import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { Status } from "../../util/types"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"

export interface UnassignedThreadAssignModalState {
  open: boolean
  unassignedThreadId?: string
  communications: Status<GraphQL.SearchCommunicationGroupsQuery>
  socialAccounts: Status<GraphQL.SearchNetworkAccountsByCommGroupIdQuery>
  conversations: Status<GraphQL.GetConversationsQuery>
  selectedCommunication: GraphQL.CommunicationGroupRowFragment | null
  selectedSocialAccount: GraphQL.CommunicationGroupNetworkAccountFragment | null
  selectedConversation: GraphQL.ConversationFragment | null
}

const initialState: UnassignedThreadAssignModalState = {
  open: false,
  communications: "init",
  socialAccounts: "init",
  conversations: "init",
  selectedCommunication: null,
  selectedSocialAccount: null,
  selectedConversation: null,
}

export const unassignedThreadAssignModal = createSlice({
  name: "UnassignedThreadAssignModal",
  initialState,
  reducers: {
    openUnassignedThreadAssignModal: (
      state,
      action: PayloadAction<{unassignedThreadId: string}>,
    ) => ({
      ...state,
      open: true,
      unassignedThreadId: action.payload.unassignedThreadId,
    }),
    closeUnassignedThreadAssignModal: (
      state,
    ) => ({
      ...state,
      open: false,
      unassignedThreadId: undefined,
      communications: "init",
      socialAccounts: "init",
      conversations: "init",
      selectedCommunication: null,
      selectedSocialAccount: null,
      selectedConversation: null,
    }),
    setCommunications: (
      state,
      action: PayloadAction<Status<GraphQL.SearchCommunicationGroupsQuery>>,
    ) => ({
      ...state,
      communications: action.payload,
    }),
    setSocialAccounts: (
      state,
      action: PayloadAction<Status<GraphQL.SearchNetworkAccountsByCommGroupIdQuery>>,
    ) => ({
      ...state,
      socialAccounts: action.payload,
    }),
    setConversations: (
      state,
      action: PayloadAction<Status<GraphQL.GetConversationsQuery>>,
    ) => ({
      ...state,
      conversations: action.payload,
    }),
    setSelectedCommunication: (
      state,
      action: PayloadAction<GraphQL.CommunicationGroupRowFragment | null>,
    ) => ({
      ...state,
      selectedCommunication: action.payload,
    }),
    setSelectedSocialAccount: (
      state,
      action: PayloadAction<GraphQL.CommunicationGroupNetworkAccountFragment | null>,
    ) => ({
      ...state,
      selectedSocialAccount: action.payload,
    }),
    setSelectedConversation: (
      state,
      action: PayloadAction<GraphQL.ConversationFragment | null>,
    ) => ({
      ...state,
      selectedConversation: action.payload,
    }),
  },
})

export const {
  openUnassignedThreadAssignModal,
  closeUnassignedThreadAssignModal,
  setCommunications,
  setSocialAccounts,
  setConversations,
  setSelectedCommunication,
  setSelectedSocialAccount,
  setSelectedConversation,
} = unassignedThreadAssignModal.actions
export default unassignedThreadAssignModal.reducer

// Thunks
export const searchCommunications = (
  searchInput: string,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setCommunications("loading"))

  const result = await API.fetchCommunications({
    startsWith: searchInput,
    limit: 10,
  })

  dispatch(setCommunications(result))
}

export const searchSocialAccounts = (
  params: GraphQL.SearchNetworkAccountsByCommGroupIdQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setSocialAccounts("loading"))

  const result = await API.searchNetworkAccountsByCommGroupId(params)

  dispatch(setSocialAccounts(result))
}

export const getConversations = (
  params: GraphQL.GetConversationsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setConversations("loading"))

  const result = await API.getConversations(params)

  dispatch(setConversations(result))
}

export const mergeConversationThread = (
  params: {
    variables: GraphQL.MergeConversationThreadMutationVariables,
    onSuccess: () => void
    onError: () => void
  },
) => async (
  dispatch: Dispatch,
) => {
  const result = await API.mergeConversationThread(params.variables)
  if (API.isSuccess(result)) {
    params.onSuccess()
  } else {
    params.onError()
  }
}

export const assignConversationThread = (
  params: {
    variables: GraphQL.AssignConversationThreadMutationVariables,
    onSuccess: () => void
    onError: () => void
  },
) => async (
  dispatch: Dispatch,
) => {
  const result = await API.assignConversationThread(params.variables)
  if (API.isSuccess(result)) {
    params.onSuccess()
  } else {
    params.onError()
  }
}
