import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { FilterMenuValue } from "../../component/FilterMenu"
import { COMMUNICATION_SEARCH_FILTERS, Status } from "../../util/types"
import {
  setCommunicationsContent,
  setCommunicationsStatus,
  setCommunicationSearchInput,
  setTemplatesStatus,
  setTemplatesContent,
  setTemplatesSearchInput,
  setCommunicationSearchFilter,
  setMoreTemplatesLoading,
  setUnassignedThreadStatus,
  setUnassignedThreadContent,
} from "."
import { RootState } from "../store"

// Campaign Interface and Initial State
export interface CommunicationsState {
  communicationsTab: number
  communicationsStatus: Status<GraphQL.SearchCommunicationGroupsQuery>
  communicationsContent: Array<GraphQL.CommunicationGroupRowFragment>
  communicationSearchInput: string
  communicationSearchFilter: FilterMenuValue
  templatesStatus: Status<GraphQL.SearchCommunicationTemplatesQuery>
  templatesContent: Array<GraphQL.CommunicationTemplateFragment>
  templatesSearchInput: string
  moreTemplatesLoading: boolean
  unassignedThreadStatus: Status<GraphQL.SearchConversationThreadsQuery>
  unassignedThreadContent: Array<GraphQL.ConversationThreadFragment>
}

export const initialState: CommunicationsState = {
  communicationsTab: 0,
  communicationsStatus: "init",
  communicationsContent: [],
  communicationSearchInput: "",
  communicationSearchFilter: {
    keyId: "", label: "", value: null,
  },
  templatesStatus: "init",
  templatesContent: [],
  templatesSearchInput: "",
  moreTemplatesLoading: false,
  unassignedThreadStatus: "init",
  unassignedThreadContent: [],
}

// Reducer actions
export const reducers = {
  setCommunicationsTab: (state: any, action: PayloadAction<number>) => ({
    ...state,
    communicationsTab: action.payload,
  }),
  setCommunicationsStatus: (state: any, action: PayloadAction<Status<GraphQL.SearchCommunicationGroupsQuery>>) => ({
    ...state,
    communicationsStatus: action.payload,
  }),
  setCommunicationsContent: (state: any, action: PayloadAction<Array<GraphQL.CommunicationGroupRowFragment>>) => ({
    ...state,
    communicationsContent: action.payload,
  }),
  setCommunicationSearchInput: (state: any, action: PayloadAction<string>) => ({
    ...state,
    communicationSearchInput: action.payload,
  }),
  setCommunicationSearchFilter: (state: any, action: PayloadAction<FilterMenuValue>) => ({
    ...state,
    communicationSearchFilter: action.payload,
  }),
  setTemplatesStatus: (state: any, action: PayloadAction<Status<GraphQL.SearchCommunicationTemplatesQuery>>) => ({
    ...state,
    templatesStatus: action.payload,
  }),
  setTemplatesContent: (state: any, action: PayloadAction<Array<GraphQL.CommunicationTemplateFragment>>) => ({
    ...state,
    templatesContent: action.payload,
  }),
  setTemplatesSearchInput: (state: any, action: PayloadAction<string>) => ({
    ...state,
    templatesSearchInput: action.payload,
  }),
  setMoreTemplatesLoading: (state: any, action: PayloadAction<boolean>) => ({
    ...state,
    moreTemplatesLoading: action.payload,
  }),
  setUnassignedThreadStatus: (state: any, action: PayloadAction<Status<GraphQL.SearchConversationThreadsQuery>>) => ({
    ...state,
    unassignedThreadStatus: action.payload,
  }),
  setUnassignedThreadContent: (state: any, action: PayloadAction<Array<GraphQL.ConversationThreadFragment>>) => ({
    ...state,
    unassignedThreadContent: action.payload,
  }),
}

export const getCommunications = (
  searchInput: string,
  filter: FilterMenuValue,
  page: number,
) => async (dispatch: Dispatch) => {
  dispatch(setCommunicationsStatus("loading"))

  const i = COMMUNICATION_SEARCH_FILTERS.findIndex((f) => f === filter.value)
  if (i === -1 && filter.value !== null) {
    dispatch(setCommunicationsStatus({ status: "error", message: "Invalid filter value!" }))
  } else {
    const result = await API.fetchCommunications({
      startsWith: searchInput,
      direction: GraphQL.SortDirection.Desc,
      filter: filter.value ? COMMUNICATION_SEARCH_FILTERS[i] : null,
      page,
      limit: 50,
    })

    dispatch(setCommunicationsStatus(result))
    if (API.isSuccess(result)) {
      dispatch(setCommunicationsContent(result.payload.searchCommunicationGroup.rows))
    }
    dispatch(setCommunicationSearchInput(searchInput))
  }
}

export const getMoreCommunications = (
  searchInput: string,
  filter: FilterMenuValue,
  page: number,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { slidingPanels: { communicationsStatus: currentCommsStatus, communicationsContent: currentCommsContent } } = getState()
  const i = COMMUNICATION_SEARCH_FILTERS.findIndex((f) => f === filter.value)
  if (i === -1 && filter.value !== null) {
    dispatch(setCommunicationsStatus({ status: "error", message: "Invalid filter value!" }))
  } else {
    const result = await API.fetchCommunications({
      startsWith: searchInput,
      direction: GraphQL.SortDirection.Desc,
      filter: filter.value ? COMMUNICATION_SEARCH_FILTERS[i] : null,
      page,
      limit: 50,
    })

    if (API.isSuccess(currentCommsStatus) && API.isSuccess(result)) {
      dispatch(setCommunicationsContent([ ...currentCommsContent, ...result.payload.searchCommunicationGroup.rows ]))
    } else {
      dispatch(setCommunicationsStatus({ status: "error", message: "Something went wrong with the request!" }))
    }
    dispatch(setCommunicationSearchInput(searchInput))
  }
}

export const getTemplates = (
  searchInput: string,
  page: number,
) => async (dispatch: Dispatch) => {
  dispatch(setTemplatesStatus("loading"))

  const result = await API.fetchCommunicationTemplates(
    searchInput,
    GraphQL.SortDirection.Desc,
    GraphQL.SearchCommunicationTemplatesSort.Created,
    page,
    50,
  )

  dispatch(setTemplatesStatus(result))
  if (API.isSuccess(result)) {
    dispatch(setTemplatesContent(result.payload.searchCommunicationTemplates.rows))
  }
  dispatch(setTemplatesSearchInput(searchInput))
}

export const getMoreTemplates = (
  searchInput: string,
  page: number,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  dispatch(setMoreTemplatesLoading(true))

  const { slidingPanels: { templatesStatus: currentTemplatesStatus, templatesContent: currentTemplatesContent } } = getState()
  const result = await API.fetchCommunicationTemplates(
    searchInput,
    GraphQL.SortDirection.Desc,
    GraphQL.SearchCommunicationTemplatesSort.Created,
    page,
    50,
  )

  if (API.isSuccess(currentTemplatesStatus) && API.isSuccess(result)) {
    dispatch(setTemplatesContent([ ...currentTemplatesContent, ...result.payload.searchCommunicationTemplates.rows ]))
  } else {
    dispatch(setTemplatesStatus({ status: "error", message: "Something went wrong with the request!" }))
  }
  dispatch(setTemplatesSearchInput(searchInput))
  dispatch(setMoreTemplatesLoading(false))
}

export const handleCommunicationFilterChange = (filter: FilterMenuValue) => (dispatch: Dispatch, getState: () => RootState) => {
  const {
    slidingPanels: {
      communicationSearchFilter,
    },
  } = getState()
  let newListTypeFilter: FilterMenuValue
  if (filter.value === null || filter.value === communicationSearchFilter.value) {
    newListTypeFilter = {
      label: "",
      value: null,
      keyId: "",
    }
  } else {
    newListTypeFilter = filter
  }
  dispatch(setCommunicationSearchFilter(newListTypeFilter))
}

export const getUnassignedThreads = (params: GraphQL.SearchConversationThreadsQueryVariables) => async (dispatch: Dispatch) => {
  dispatch(setUnassignedThreadStatus("loading"))

  const result = await API.searchConversationThreads(params)

  dispatch(setUnassignedThreadStatus(result))
  if (API.isSuccess(result)) {
    dispatch(setUnassignedThreadContent(result.payload.searchConversationThreads.rows))
  }
}

export const getMoreUnassignedThreads = (
  params: GraphQL.SearchConversationThreadsQueryVariables,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { slidingPanels: { unassignedThreadContent: currentContent, unassignedThreadStatus: currentStatus } } = getState()
  const result = await API.searchConversationThreads(params)

  if (API.isSuccess(currentStatus) && API.isSuccess(result)) {
    dispatch(setUnassignedThreadContent([ ...currentContent, ...result.payload.searchConversationThreads.rows ]))
  } else {
    dispatch(setUnassignedThreadStatus({ status: "error", message: "Something went wrong with the request!" }))
  }
}
