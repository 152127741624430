import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { Status } from "../../util/types"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { RootState } from "../store"

// Customer User Slice Interface and Initial State
export interface CustomerUserPageState {
  customerStatus: Status<GraphQL.GetCustomerQuery>
  campaignsStatus: Status<GraphQL.SearchCampaignsCustomerUserQuery>
  moreCampaigns: GraphQL.CampaignForCustomerUserRowFragment[]
  userStatus: Status<GraphQL.UserForCustomerManagementQuery>
  campaignSearchInput: string
  searching: boolean
}

const initialState: CustomerUserPageState = {
  customerStatus: "init",
  campaignsStatus: "init",
  moreCampaigns: [],
  userStatus: "init",
  campaignSearchInput: "",
  searching: false,
}

// Customer User Page Slice
export const CustomerUserPageSlice = createSlice({
  name: "CustomerUserPageSlice",
  initialState,
  reducers: {
    setCustomerStatus: (state, action: PayloadAction<Status<GraphQL.GetCustomerQuery>>) => ({
      ...state,
      customerStatus: action.payload,
    }),
    setCampaignsStatus: (state, action: PayloadAction<Status<GraphQL.SearchCampaignsCustomerUserQuery>>) => ({
      ...state,
      campaignsStatus: action.payload,
    }),
    setUserStatus: (state, action: PayloadAction<Status<GraphQL.UserForCustomerManagementQuery>>) => ({
      ...state,
      userStatus: action.payload,
    }),
    setCampaignSearchInput: (state, action: PayloadAction<string>) => ({
      ...state,
      campaignSearchInput: action.payload,
    }),
    setSearching: (state, action: PayloadAction<boolean>) => ({
      ...state,
      searching: action.payload,
    }),
    setMoreCampaigns: (state, action: PayloadAction<GraphQL.CampaignForCustomerUserRowFragment[]>) => ({
      ...state,
      campaigns: action.payload,
    }),
  },
})

export const {
  setCustomerStatus,
  setCampaignsStatus,
  setUserStatus,
  setCampaignSearchInput,
  setSearching,
  setMoreCampaigns,
} = CustomerUserPageSlice.actions
export default CustomerUserPageSlice.reducer

export const getCustomer = (
  params: GraphQL.GetCustomerQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setCustomerStatus("loading"))

  const result = await API.fetchCustomer(params)

  dispatch(setCustomerStatus(result))
}

export const getCustomerUser = (
  params: GraphQL.UserForCustomerManagementQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setCustomerStatus("loading"))

  const result = await API.fetchCustomerUser(params)

  dispatch(setUserStatus(result))
}

export const searchCustomerUserCampaigns = (
  params: GraphQL.SearchCampaignsCustomerUserQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setCustomerStatus("loading"))

  const result = await API.searchCampaignForCustomerUser(params)

  dispatch(setCampaignsStatus(result))
}

export const searchMoreCustomerUserCampaigns = (
  params: GraphQL.SearchCampaignsCustomerUserQueryVariables,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const {
    customerUserPageSlice: {
      moreCampaigns: currentCampaigns,
    },
  } = getState()

  dispatch(setSearching(true))

  const result = await API.searchCampaignForCustomerUser(params)

  if (API.isSuccess(result)) {
    dispatch(setMoreCampaigns([ ...currentCampaigns, ...result.payload.searchCampaignForCustomerUser.rows ]))
  }
  dispatch(setSearching(false))
}

export const resetAllFields = () => (dispatch: Dispatch) => {
  dispatch(setCampaignSearchInput(""))
  dispatch(setCampaignsStatus("init"))
  dispatch(setUserStatus("init"))
  dispatch(setCustomerStatus("init"))
}
